import {UnControlled as CodeMirror} from 'react-codemirror2'
import React, { useEffect, useRef } from 'react';
import files from "./files";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { useEdges } from 'reactflow/dist/esm';
import axios from 'axios';
import { toast } from 'react-toastify';
import { fpost } from '../../../axios/axios';
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');

const CodeEditor = ({innerRef, activeTab, collector_name, checkFlag, setCheckFlag, isEdit}) => {
    // Your component logic here
    const apiBaseUrl = localStorage.getItem("apiBaseUrl")

    const file = files[activeTab];
    useEffect(() => {
        if(!isEdit){
        innerRef.current = file?.value;
        }
    }, [activeTab]);
    const [show, setShow] = React.useState(false);
    const testConf = (e)=>{
        e.stopPropagation();

        fpost(`${apiBaseUrl}/logstash/configs/test?collector_name=${collector_name}`,{
            file_content:  btoa(unescape(encodeURIComponent(innerRef.current))),
        }).then((res)=>{
            console.log("Test Conf",res)
            toast.success("Test Successfull")
            setCheckFlag(true)
            setShow(true)
        }).catch((err)=>{
            console.log("Error",)
            toast.error("Test Failed")
            setCheckFlag(false)
            setShow(true)
        })
    }
    return (
        <>
        {/* <div className='d-flex justify-content-end mb-2 gap-2'>
            <button type="button"  className="btn btn-primary " onClick={(e)=>testConf(e)}>Test</button>
        </div> */}
                <CodeMirror
                height="60vh"
                options={{
                    mode: 'xml',
                    theme: 'material',
                    // lineNumbers: true
                }}
                theme="vs-dark"
                value={isEdit?  innerRef.current:file?.value}
                onChange={(editor, data, value) => {
                    console.log(value);
                    innerRef.current = value;   
                }}
            />
    </>
    );
};

export default CodeEditor;