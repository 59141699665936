const inputExample = `
input {
  beats {
    port => 5044
  }
}

`;

const outputExample  = `
output {
  elasticsearch {
    hosts => ["http://localhost:9200"]
    index => "%{[@metadata][beat]}-%{[@metadata][version]}-%{+YYYY.MM.dd}"
    #user => "$ELASTICSEARCH_USER"
    #password => "$ELASTICSEARCH_PASSWORD"
  }
}
`;

const  filterExample= `
filter {
  grok {
    match => { "message" => "%{COMBINEDAPACHELOG}" }
  }
  date {
    match => [ "timestamp" , "dd/MMM/yyyy:HH:mm:ss Z" ]
  }
}
`;

const files = {
    "input": {
      name: "input",
      language: "javascript",
      value: inputExample
    },
    "filter": {
      name: "filter",
      language: "javascript",
      value: filterExample
    },
    "output": {
      name: "output",
      language: "javascript",
      value: outputExample
    }
  };
  
  export default files;