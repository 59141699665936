import React, { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import styles from "./Login.styles";
import { Amplify, Auth } from "aws-amplify";
import cybotLogo from "../../assets/images/cybotlogo.svg";
import CustomModal from "../../components/CustomModal/CustomModal";
import abVideo from "../../assets/videos/activebytes.mp4";
import Spinner from "react-bootstrap/Spinner";
import AlertBox from "../../components/AlertBox/AlertBox";
import { fget, fpost } from "../../axios/axios";
import { Hub, HubCallback } from '@aws-amplify/core';
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";


const quotesData = [
  "We're all going to have to change how we think about data protection",
  "Arguing that you don’t care about the right to privacy because you have nothing to hide is no different than saying you don’t care about free speech because you have nothing to say.",
  "As we’ve come to realize, the idea that security starts and ends with the purchase of a prepackaged firewall is simply misguided.",
];
const hostname = window.location.hostname
if (
  hostname.endsWith('.active-bytes.com') ||
  hostname === 'active-bytes.com' ||
  hostname === 'localhost'
) {
  var amplifyConfig = {
    Auth: {
      userPoolId: process.env.REACT_APP_USER_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_WEBCLIENT_ID,
    },
  }
}
else {
  var amplifyConfig = {
    Auth: {
      userPoolId: process.env.REACT_APP_USER_ID,
      userPoolWebClientId: process.env.REACT_APP_USER_WEBCLIENT_ID,
      oauth: {
        domain: process.env.REACT_APP_OAUTH_DOMAIN,

        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
    },
  }
}
Amplify.configure(amplifyConfig);

const Login = ({ setIsLoggedIn }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [quote, setQuote] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [forgotUsername, setForgotUsername] = useState("");
  const [forgotPage, setForgotPage] = useState("username");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [apiBaseUrl, setApiBaseUrl] = useState("")
  const [accessModule, setAccessModule] = useState("");
  const [isAzureAuth, setIsAzureAuth] = useState(false)
  const [isSsoAuth, setIsSsoAuth] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const initalSetup = () => {
    if (
      hostname.endsWith('.active-bytes.com') ||
      hostname === 'active-bytes.com' ||
      hostname === 'localhost'
    ) {
      let apiBase = process.env.REACT_APP_SSO_URL_V2
      setApiBaseUrl(apiBase)
      localStorage.setItem("apiBaseUrl", apiBase)
    }
    else if (hostname.endsWith('knpc.com')) {
      let apiBase = `https://${hostname}:${process.env.REACT_APP_PROXY_PORT}/api/v2`
      setApiBaseUrl(apiBase)
      setIsSsoAuth(true)
      localStorage.setItem("apiBaseUrl", apiBase)
    }
    else {
      let apiBase = `https://${hostname}:${process.env.REACT_APP_PROXY_PORT}/api/v2`
      setApiBaseUrl(apiBase)
      setIsAzureAuth(true)
      localStorage.setItem("apiBaseUrl", apiBase)
    }
  }


  // alert
  const [alertPopup, setAlertPopup] = useState(false)
  const [loginFailedMessage, setLoginFailedMessage] = useState(false)
  const [init, setInit] = useState(true)

  let count = 0;

  // const today = new Date();
  const hrs = new Date().getHours();

  var greeting;

  if (hrs < 12) greeting = "Good Morning!";
  else if (hrs >= 12 && hrs <= 17) greeting = "Good Afternoon!";
  else if (hrs >= 17 && hrs <= 24) greeting = "Good Evening!";

  useEffect(() => {
    if (init) {
      let notRemove = [
        "investigateCheckedTableColumns",
        "ManageColumn.investigate.cykit.firewall",
        "ManageColumn.investigate.cykit.ad",
        "ManageColumn.investigate.cykit.email",
        "ManageColumn.investigate.cykit.waf",
        "ManageColumn.investigate.cykit.office.ad",
        "ManageColumn.investigate.cykit.office.oneDrive",
        "ManageColumn.investigate.cykit.office.exchange",
        "filters",
        "filter",
        "lte",
        "gte",
        "recentSearches",
        "apiBaseUrl"
      ]
      Object.keys(localStorage).forEach((key) => {
        if (!notRemove.includes(key)) {
          localStorage.removeItem(key);
        }
      });
    }
    setQuote(quotesData[count]);
    const timer = setInterval(() => {
      setQuote(quotesData[count]);
      count += 1;
      if (count == 3) count = 0;
    }, 4000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const onSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    Auth.signIn(username, password)
      .then((result) => {
        console.log("login result", result);
        let jwt = result.signInUserSession.idToken.jwtToken;
        let userName = result.username;
        localStorage.setItem("loggedUser", userName);
        localStorage.setItem("auth", jwt);
        licenseInfo()
        getSystemVar()
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertPopup(true)
        const message = err.message
        setLoginFailedMessage(message)
      });
  };

  const changePassword = (e) => {
    e.preventDefault();
    console.log("inside");
    // Auth.forgotPassword(forgotUsername)
    //   .then(data => console.log("forgot", data))
    //   .catch(err => console.log("forgot err", err));
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    console.log("inside");
    Auth.forgotPassword(forgotUsername)
      .then((data) => {
        console.log("forgot", data);
        setResetEmail(data?.CodeDeliveryDetails?.Destination);
        setForgotPage("changePassword");
      })
      .catch((err) => console.log("forgot err", err));
  };

  const licenseInfo = async () => {
    let accessVars = JSON.parse(localStorage.getItem("accessVars"));
    if (accessVars) {
      await setAccessModule(accessVars)
    }
    else {
      let accessCheckData = await fget(
        `${apiBaseUrl}/core/userpermissions`
      )
      await localStorage.setItem("accessVars", JSON.stringify(accessCheckData.data))
      await setAccessModule(accessCheckData.data)
    }

    // let { data: { analytics, compliance, hunter, investigate, rulesstudio, rulesstudioadmin, ti, ueba } } = accessCheckData
  }
  const handleCallback = useCallback(async () => {
    if (hostname.endsWith('knpc.com')) {
      var auth_code = searchParams.get("auth_code")
      var error = searchParams.get("error_code")
      if (auth_code != undefined || error != undefined) {
        setIsLoading(true);
        try {
          // Check if the user is authenticated
          if (auth_code != undefined) {
            try {
              let data = {
                "auth_code": auth_code
              };

              await axios.post(
                `${apiBaseUrl}/auth_exchange`,
                data
              ).then((res) => {
                searchParams.delete('auth_code');
                setSearchParams(searchParams)
                localStorage.setItem("loggedUser", res?.data?.username);
                localStorage.setItem("auth", res?.data?.access_token);
                localStorage.setItem("refresh", res?.data?.refresh_token);
                localStorage.setItem("is_onprem", true);
                licenseInfo()
                getSystemVar()
              })

            } catch (error) {
              console.log("authenticate error", error);
            }

            // localStorage.setItem("loggedUser", userName);
            // localStorage.setItem("auth", jwt);
            // setIsLoggedIn(true);
          }
          if (error != undefined) {
            toast.error(error, {
              position: "bottom-right",
            })
          }

          // Redirect to the desired page upon successful authentication
        } catch (error) {
          console.error('Error during callback:', error);
          // Handle errors or redirect to an error page
        }
      }

    }
    else {
      try {
        // Check if the user is authenticated
        const user = await Auth.currentAuthenticatedUser();
        console.log('Authenticated user:', user);
        let jwt = user.signInUserSession.idToken.jwtToken;
        let userName = user.username;
        localStorage.setItem("loggedUser", userName);
        localStorage.setItem("auth", jwt);
        setIsLoggedIn(true);
        // Redirect to the desired page upon successful authentication
      } catch (error) {
        console.error('Error during callback:', error);
        // Handle errors or redirect to an error page
      }
    }
  }, [isAzureAuth, isSsoAuth])
  useEffect(() => {
    handleCallback();
  }, []);
  const signInWithAzureAd = async () => {
    try {
      Auth.federatedSignIn({ provider: 'test' })
        .catch((err) => {
          setIsLoading(false);
          setAlertPopup(true)
          const message = err.message
          setLoginFailedMessage(message)
        })
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  }


  useMemo(() => {
    initalSetup()
  }, [])

  const getSystemVar = async () => {
    try {
      const response = await fget(
        // `${process.env.REACT_APP_SSO_URL}/getsystemvars`
        `${apiBaseUrl}/core/systemvars`
        // "https://api-gw1.active-bytes.com/api/v2/core/systemvars"
      );



      if (response.data.datalakeuname && response.data.datalakepass) {
        // localStorage.setItem("ACCESS_TOKEN", "Basic" + btoa(`${data.datalakeuname}:${data.datalakepass}`))
        await localStorage.setItem(
          "DATALAKE_ACCESS_TOKEN",
          `Basic ${btoa(
            `${response.data.datalakeuname}:${response.data.datalakepass}`
          )}`
        );
      }
      if (response.data.datalakeurl) {
        localStorage.setItem("DATALAKE_URL", response.data.datalakeurl);
      }
      if (response.data.cyboturl) {
        localStorage.setItem("CYBOT_URL", response.data.cyboturl);
      }
      if (response.data.datalakeuname && response.data.datalakepass) {
        localStorage.setItem("DatalakeUname", response.data.datalakeuname);
        localStorage.setItem("DatalakePass", response.data.datalakepass);
      }
      if (response.data.edr_type) {
        localStorage.setItem("EDR_TYPE", response.data.edr_type);
      }
      if (response.data.cybot_cykit_index_pattern) {
        localStorage.setItem("CYBOT_CYKIT_INDEX_PATTERN", response.data.cybot_cykit_index_pattern);
      }
      if (response.data.cybot_cykit_url) {
        localStorage.setItem("CYBOT_CYKIT_URL", response.data.cybot_cykit_url);
      }
      if (response.data.variables.filter((item) => item.name === "investigate_cykit_firewall").length > 0) {
        localStorage.setItem("INVESTIGATE_CYF", response.data.variables.filter((item) => item.name === "investigate_cykit_firewall")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "investigate_cykit_ad").length > 0) {
        localStorage.setItem("INVESTIGATE_CYAD", response.data.variables.filter((item) => item.name === "investigate_cykit_ad")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "investigate_cykit_email").length > 0) {
        localStorage.setItem("INVESTIGATE_CYEMAIL", response.data.variables.filter((item) => item.name === "investigate_cykit_email")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "investigate_cykit_waf").length > 0) {
        localStorage.setItem("INVESTIGATE_CYWAF", response.data.variables.filter((item) => item.name === "investigate_cykit_waf")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "investigate_cykit_atp_alerts").length > 0) {
        localStorage.setItem("INVESTIGATE_CY_ATP_ALERTS", response.data.variables.filter((item) => item.name === "investigate_cykit_atp_alerts")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "investigate_cykit_o365").length > 0) {
        localStorage.setItem("INVESTIGATE_o365", response.data.variables.filter((item) => item.name === "investigate_cykit_o365")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "cykit_combined_pattern").length > 0) {
        localStorage.setItem("CYBOT_CYKIT_COMBINED_PATTERN", response.data.variables.filter((item) => item.name === "cykit_combined_pattern")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "is_unified").length > 0) {
        localStorage.setItem("CYBOT_IS_UNIFIED", response.data.variables.filter((item) => item.name === "is_unified")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "is_updated_proxy").length > 0) {
        localStorage.setItem("IS_UPDATED_PROXY", response.data.variables.filter((item) => item.name === "is_updated_proxy")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "is_updated_datalake_proxy").length > 0) {
        localStorage.setItem("IS_UPDATED_DATALAKE_PROXY", response.data.variables.filter((item) => item.name === "is_updated_datalake_proxy")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "case_mgmt_base_url").length > 0) {
        localStorage.setItem("CASE_MANAGEMENT_URL", response.data.variables.filter((item) => item.name === "case_mgmt_base_url")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "analytics_mode").length > 0) {
        localStorage.setItem("ANALYTICS_MODE", response.data.variables.filter((item) => item.name === "analytics_mode")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "analytics-ui-url").length > 0) {
        localStorage.setItem("analytics-ui-url", response.data.variables.filter((item) => item.name === "analytics-ui-url")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "investigate_cykit_software").length > 0) {
        localStorage.setItem("INVESTIGATE_CY_SOFTWARE", response.data.variables.filter((item) => item.name === "investigate_cykit_software")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "investigate_cykit_vulns").length > 0) {
        localStorage.setItem("INVESTIGATE_CY_VULNS", response.data.variables.filter((item) => item.name === "investigate_cykit_vulns")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "threat_lanscape_firewall").length > 0) {
        localStorage.setItem("THREAT_LANDSCAPE_FIREWALL", response.data.variables.filter((item) => item.name === "threat_lanscape_firewall")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "third_party_iocs_index").length > 0) {
        localStorage.setItem("THIRD_PARTY_IOCS_INDEX", response.data.variables.filter((item) => item.name === "third_party_iocs_index")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "hunter_cykit_log_pattern").length > 0) {
        localStorage.setItem("HUNTER_CYKIT_LOG_PATTERN", response.data.variables.filter((item) => item.name === "hunter_cykit_log_pattern")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "hunter_cykit_filebeat_enabled").length > 0) {
        localStorage.setItem("HUNTER_CYKIT_FILEBEAT_ENABLED", response.data.variables.filter((item) => item.name === "hunter_cykit_filebeat_enabled")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "ti_cykit_log_pattern").length > 0) {
        localStorage.setItem("TI_CYKIT_LOG_PATTERN", response.data.variables.filter((item) => item.name === "ti_cykit_log_pattern")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "hunter_all_logs_index_id").length > 0) {
        localStorage.setItem("HUNTER_INDEX_ALL_INDEX_ID", response.data.variables.filter((item) => item.name === "hunter_all_logs_index_id")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "compliance-ui-url").length > 0) {
        localStorage.setItem("COM_UI_URL", response.data.variables.filter((item) => item.name === "compliance-ui-url")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "corelation-filter-fields").length > 0) {
        localStorage.setItem("CORELATION_FILTER_FIELDS", response.data.variables.filter((item) => item.name === "corelation-filter-fields")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "corelation-fields").length > 0) {
        localStorage.setItem("CORELATION_FIELDS", response.data.variables.filter((item) => item.name === "corelation-fields")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "corelation-index").length > 0) {
        localStorage.setItem("CORELATION_INDEX", response.data.variables.filter((item) => item.name === "corelation-index")[0].value);
      }
      if (response.data.variables.filter((item) => item.name === "managed-ingestion").length > 0) {
        localStorage.setItem("MANAGED_INGESTION", response.data.variables.filter((item) => item.name === "managed-ingestion")[0].value);
      }
      if (
        response.data.cybotuname &&
        response.data.cybotpass &&
        response.data.cyboturl
      ) {


        localStorage.setItem("CM_ACCESS_TOKEN", btoa(`${response.data.cybotuname}:${response.data.cybotpass}`));

        try {
          let userData = {
            username: response.data.cybotuname,
            password: response.data.cybotpass,
          };

          const response2 = await fpost(
            `${response.data.cyboturl}/authenticate`,
            userData
          );


          response2?.data?.user_token &&
            localStorage.setItem(
              "CYBOT_ACCESS_TOKEN",
              response2.data.user_token
            );

        } catch (error) {
          console.log("authenticate error", error);
        }
      }

      // cybotLogin(userData, response.datacyboturl)
      //   .then(({ data: { user_token } }) => {
      //     user_token &&
      //       localStorage.setItem("CYBOT_ACCESS_TOKEN", response.data.user_token);
      //   })
      //   .catch((err) => {
      //     // alert("Error", err);
      //     console.log("error", err);
      //   });
    } catch (error) {
      console.log("home error", error);
    }

    setIsLoggedIn(true);

  };

  const renderModal = () => (
    <>
      {forgotPage === "username" ? (
        <form
          onSubmit={forgotPassword}
          className="d-flex flex-column gap-3"
          style={styles.form}
        >
          {/* <input
        value={forgotEmail}
        onChange={(event) => setForgotEmail(event.target.value)}
        placeholder="Email"
        className="form-control form-control-sm p-3"
      // style={styles.inputBox}
      /> */}
          <input
            value={forgotUsername}
            onChange={(event) => setForgotUsername(event.target.value)}
            placeholder="Username"
            className="form-control form-control-sm p-3"
          // style={styles.inputBox}
          />
          <button
            type="submit"
            className="btn btn-dark p-3"
            style={styles.button}
          >
            {isLoading2 ? "Loading..." : "Reset my password"}
          </button>
        </form>
      ) : (
        <form
          onSubmit={changePassword}
          className="d-flex flex-column gap-3"
          style={styles.form}
        >
          <span className="p-3">
            We have sent a password reset code by email to {resetEmail}. Enter
            it below to reset your password
          </span>
          <input
            value={code}
            onChange={(event) => setCode(event.target.value)}
            placeholder="Code"
            className="form-control form-control-sm p-3"
          />
          <input
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            placeholder="New password"
            className="form-control form-control-sm p-3"
          />
          <input
            value={newPasswordAgain}
            onChange={(event) => setNewPasswordAgain(event.target.value)}
            placeholder="Enter New Password Again"
            className="form-control form-control-sm p-3"
          />
          <button
            type="submit"
            className="btn btn-dark p-3"
            style={styles.button}
          >
            {isLoading2 ? "Loading..." : "Change Password"}
          </button>
        </form>
      )}
    </>
  );

  return (
    <div className="" style={styles.mainContainer}>
      <div
        className="col-8 position-relative overflow-hidden"
        style={styles.quoteContainer}
      >
        {/* <video
          autoPlay
          muted
          loop
          id="myVideo"
          className="position-absolute h-100"
          style={{ right: "-20%" }}
        >
          <source
            src="https://xulife.s3.ap-south-1.amazonaws.com/activebytes.mp4"
            type="video/mp4"
          />
        </video> */}
        <div
          className="col-12 z-2 h-100 d-flex flex-column justify-content-between"
          style={{ zIndex: 1 }}
        >
          <div className="col-2 m-4 p-4">
            <img
              src={cybotLogo}
              alt="Cybot Logo"
              style={styles.logo}
              className=""
            />
          </div>
          <div className="m-5 p-4">
            <h2 style={{ color: "white" }}>
              " <br />
              {quote}
            </h2>
          </div>
        </div>
      </div>

      <div className="col-4" style={styles.loginContainer}>
        <div className="col-8">
          <div className="d-flex flex-column">
            <p className="mb-1">Hi,</p>
            <h4 className="mb-5">{greeting}</h4>
          </div>
          <form
            onSubmit={onSubmit}
            className="d-flex flex-column gap-3"
            style={styles.form}
          >
            {!isSsoAuth && <>
              <input
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                placeholder="Username"
                className="form-control form-control-sm p-3"
              // style={styles.inputBox}
              />
              <input
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                placeholder="Password"
                type="password"
                className="form-control form-control-sm p-3"
              // style={styles.inputBox}
              />
              <span
                onClick={() => {
                  console.log("hello");
                  // setOpenModal(true);
                  window.open(
                    `https://sso.active-bytes.com/forgotPassword?&response_type=code&client_id=28a2iuqcm16vlbskb9fodg8rgo&redirect_uri=${window.location.href}`,
                    "_self"
                  );
                }}
                style={{
                  textAlign: "right",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                Forgot Password?
              </span>
              <button
                type="submit"
                className="btn btn-dark p-3"
                style={styles.button}
              >
                {isLoading ? (
                  <Spinner animation="border" role="status" size="sm" />
                ) : (
                  "Sign in"
                )}
              </button>
            </>}
            {(isAzureAuth) && <span className="text-center"> OR </span>}
            {isSsoAuth && <button
              onClick={() => {
                console.log("hello");
                // setOpenModal(true);
                window.open(
                  `${apiBaseUrl}/login`,
                  "_self"
                );
              }}
              className="btn btn-dark p-3"
              type="button"
              style={styles.button}
            >
              {isLoading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                "Sign in With SSO"
              )}
            </button>}
            {isAzureAuth && <button
              onClick={signInWithAzureAd}
              className="btn btn-dark p-3"
              type="button"
              style={styles.button}
            >
              {isLoading ? (
                <Spinner animation="border" role="status" size="sm" />
              ) : (
                "Sign in With Azure AD"
              )}
            </button>}
          </form>

        </div>
      </div>
      <CustomModal
        title={"Reset Password"}
        ShowOrHide={openModal}
        bodyData={renderModal}
        handleClose={() => setOpenModal(false)}
        footer={false}
        modalSize="sm"
        autoHeight={true}
      />

      {alertPopup && (
        <AlertBox setAlertPopup={setAlertPopup} alertPopup={alertPopup} message={loginFailedMessage} status={"error"} alertPosition={"top-center"} />
      )}
    </div>
  );
};

export default Login;



