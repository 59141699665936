import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  Form,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import styles from "../Settings.styles";
import TableLoader from "../../../components/Loader/TableLoader";
import NoDataFound from "../../../components/NoDataFount/NoDataFound";
import globalStyles from '../../../constants/globalStyles';
import { Link } from "react-router-dom";

import { fget, fgetCy, fpostCy } from "../../../axios/axios";
import { SingleLoader, SingleTileLoader } from "../../../components/Loader/SingleTileLoader";
import TableViewMore from "../../../components/ViewMoreBtn/TableViewMore";
import { ArrowClockwise, ArrowsClockwise, DotsThreeOutlineVertical, PencilSimple, X } from "phosphor-react";
import moment from "moment";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
} from "chart.js";
import axios from "axios";
import PolicySidebar from "../../../components/PolicySideBar/PolicySidebar";
import { toast } from "react-toastify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const LogManagement = () => {
  const datalakeUrl = localStorage.getItem("DATALAKE_URL")
  const apiBaseUrl = localStorage.getItem("apiBaseUrl")
  
  const [timeFrame, setTimeFrame] = useState("1h");
  const [tableData, setTableData] = useState([]);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [graphPreData, setGraphPreData] = useState([]);

  const [activeKeyword, setActiveKeyword] = useState(null)
  const [expclose, setExpClose] = useState(false);
  const [clickedPolicy, setClickedPolicy] = useState(null);
  const dayFormat = {
    unit: 'day',
    unitStepSize: 1,
    displayFormats: {
      'millisecond': 'MMM DD',
      'second': 'MMM DD',
      'minute': 'MMM DD',
      'hour': 'MMM DD',
    }
  }

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
      },
    },
    scales: {
      xAxis: {
        type: 'time',
        time: timeFrame === "1d" ? dayFormat : {}
      }
    },

  };
  const graphData = {
    datasets: [
      {
        label: "Ingested Log Count",
        data: graphPreData,
        fill: false,
        borderColor: 'rgb(54, 162, 235)',
        tension: 0.1
      },
    ],

  };

  useEffect(() => {
    setTableIsLoading(true)
    fget(`${apiBaseUrl}/logstash/collectors/`).then(({ data }) => {
      setTableData(data)
      setTableIsLoading(false)
    }).catch((err) => {
      console.log("Error", err)
    })
  }, [])

  const getGraphData = (collector_name) => {
    fget(`${apiBaseUrl}/logstash/configs/configs?collector_name=${collector_name}`).then(({ data }) => {
      setGraphPreData(data)

    }).catch((err) => {
      setGraphPreData([])
      console.log("Error", err)
    })
  }
  const restartCollector = (collector_name) => {
    fget(`${apiBaseUrl}/logstash/configs/restart?collector_name=${collector_name}`).then((res) => {
      if(res.status===200){
        toast.success("Collector Restarted")
      }
      else{
        toast.error("Collector Failed To Restart ")
      }
    }).catch((err) => {
      console.log("Error", err)
    })
  }
  const stopCollector = (collector_name) => {
    fget(`${apiBaseUrl}/logstash/configs/stop?collector_name=${collector_name}`).then((res) => {
        if(res.status===200){
          toast.success("Collector Stopped")
        }
        else{
          toast.error("Collector Failed To Stop ")
        }
    }).catch((err) => {
      console.log("Error", err)
    })
  }
  const startCollector = (collector_name) => {
    fget(`${apiBaseUrl}/logstash/configs/start?collector_name=${collector_name}`).then((res) => {
      if(res.status===200){
        toast.success("Collector Started")

      }
      else{
        toast.error("Collector Failed To Start ")
      }
    }).catch((err) => {
      console.log("Error", err)
    })
  }
  const showDetails = (item) => {
    fget(`${apiBaseUrl}/logstash/configs/config?pipeline_name=${item?.["pipeline.id"]}&collector_name=${activeKeyword}`).then(({ data }) => {
        setClickedPolicy(data)
        setExpClose(true)
      }).catch((err) => {
        console.log("Error", err)
      })
  }
  const [itemStatus, setItemStatus] = useState("checking")
  const [logs, setLogs] = useState("")
  const getStatus = () => {
    fget(`${apiBaseUrl}/logstash/configs/health-check?collector_name=${activeKeyword}`).then(({ data }) => {
        setItemStatus(data?.status)
        setOutput(data?.output)
      }).catch((err) => {
        console.log("Error", err)
        setItemStatus("down")
      })
  }
  const getStatusLogs = () => {
    fget(`${apiBaseUrl}/logstash/configs/get-logs?collector_name=${activeKeyword}`).then(({ data }) => {
        setLogs(data?.output)
      }).catch((err) => {
        console.log("Error", err)
        setItemStatus("down")
      })
  }

const [output, setOutput] = useState("")
  const ConnectorList = ({item}) => {
    const [itemStatusLoading, setItemStatusLoading] = useState(true)


      useEffect(() => {
        getStatus(item)
      }, [item])
    return (
        <div className='col-12 d-flex flex-row justify-content-between'>
                <span>
                  {item?.name}
                </span>
                {/* <span>
                  {itemStatusLoading?<Spinner/>:itemStatus === "active" ? <span style={{ color: "green" }}>Up</span> : <span style={{ color: "red" }}>Down</span>}
                </span> */}
              </div>
    )
  }
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    // <div
    //   className="col-12 p-4 position-relative rounded-3"
    //   style={styles.section1}
    // >
    //   <div
    //     className="d-flex flex-row align-items-center justify-content-end ml-auto col-7"
    //     style={styles.filterWrap}
    //   >
    //   </div>

    //   <div className="detections col-12">
    //     <div className="col-12" style={styles.tableWrap}>
    //       {tableIsLoading ? (
    //         <div className="col-12">
    //           <TableLoader rowCount={25} />
    //         </div>
    //       ) : tableData.length !== 0 ? (
    //         <Table borderless hover>
    //           <thead className="fixed-head">
    //             <tr>
    //               <th>Index</th>
    //             </tr>
    //           </thead>
    //           <tbody>
    //             {tableData.map((item) => (
    //               <tr>
    //                 <td>
    //                   <Link to={`/settings/index-health/${item.name}`} style={{ textDecoration: 'none', color: '#000000' }}>
    //                     {item.name}
    //                   </Link>
    //                 </td>
    //               </tr>
    //             ))}
    //           </tbody>
    //         </Table>
    //       ) : (
    //         <NoDataFound errorText={"No data Found"} />
    //       )}
    //     </div>

    //   </div>

    // </div>

    <>
      <div
        className="d-flex flex-fill flex-column p-0 rounded-3 bg-white gap-3"
        style={styles.overviewWrap}
      >

        <div className='ignore-onclickoutside d-flex flex-column col-12 p-4 overflow-auto' style={{}}>
          <h4 className="px-2">Collectors</h4>
          <ul className='col-12 p-0 m-0 d-flex flex-column gap-3'>
            {tableIsLoading ? (
              <SingleTileLoader width="100%" count="13" />
            ) : (
              <>
                {!tableData ? (
                  <>
                    <NoDataFound errorText={"No data found"} />
                  </>
                ) : (
                    <div style={styles.contentWrap}>
        {
          tableData.map((item) => (
            <li className='p-3 rounded cursor-pointer list-item d-flex align-items-center'
              style={{
                backgroundColor:
                  item?.name === activeKeyword
                    ? globalStyles.yellowLite1
                    : globalStyles.white,
                listStyle: "none"
              }}

              onClick={(e) => {
                e.stopPropagation()
                getGraphData(item?.name);
                setActiveKeyword(item?.name);
                getStatus()
                getStatusLogs()
              }}
            >
              
              <ConnectorList item={item}/>
            </li>
          ))
        }
      </div>    
                )}
              </>
            )}
          </ul>
        </div>

      </div>
      <div className="col-8 p-4 rounded-3 bg-white position-relative">
        {activeKeyword&&<div className="d-flex flex-row justify-content-between">
          <h4 class>Collector - {activeKeyword}    </h4>
          <div className="d-flex gap-4">
            <h5 className="mt-1">Status - {itemStatus && capitalizeFirstLetter(itemStatus) }</h5>
            <DropdownButton id="dropdown-basic-button"   className="no-caret" variant="light" title={<DotsThreeOutlineVertical size={18} />}>
            <Dropdown.Item onClick={()=>{startCollector('start')}}>Start</Dropdown.Item>
            <Dropdown.Item onClick={()=>{stopCollector('stop')}}>Stop</Dropdown.Item>
            <Dropdown.Item onClick={()=>{restartCollector('restart')}}>Restart</Dropdown.Item>
          </DropdownButton>
          <Link to={`/settings/managed-ingestion-create/${activeKeyword}`} >
          <button className="btn btn-primary" >
            Create New Policy
          </button>
          </Link>
          </div>
          
        </div>}
       
        <div className='col-12 d-flex flex-column gap-3 overflow-auto mt-2' style={{ paddingRight: "1rem", height: "calc(100% - 10rem)" }}>
          {activeKeyword ? (
             <Tabs>
             <Tab eventKey="home" title="Policies">
                  <>
                  <div className="col-12" style={styles.tableWrap}>
                                    {tableIsLoading ? (
                                        <div className="col-12">
                                        <TableLoader rowCount={25} />
                                        </div>
                                    ) : graphPreData?.length !== 0 ? (
                                        <Table borderless hover>
                                        <thead className="fixed-head">
                                            <tr>
                                            <th>Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {graphPreData?.map((item) => (
                                            <tr onClick={()=>showDetails(item)}>
                                                <td >
                                                    {item?.["pipeline.id"].replace(/\.conf$/, '')}
                                                </td> 
    
                                            </tr>
                                            ))}
                                        </tbody>
                                        </Table>
                                    ) : (
                                        <NoDataFound errorText={"No data Found"} />
                                    )}
                                    </div>
                </>
             </Tab>
             <Tab eventKey="status" title="Status">
             <div style={{ padding: '1rem', backgroundColor: '#f5f5f5', borderRadius: '5px', overflow: 'auto' }}>
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                      {output}
                    </pre>
                  </div>
              </Tab>
              <Tab eventKey="logs" title="Logs">
                <div style={{ padding: '1rem', backgroundColor: '#f5f5f5', borderRadius: '5px', overflow: 'auto' }}>
                        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                          {logs}
                        </pre>
                      </div>
              </Tab>
           </Tabs>
            
          ) : (
            <span style={{ fontSize: "1.5rem", color: "red", fontWeight: "600", display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>Select Collector</span>
          )}
        </div>
        <PolicySidebar data={clickedPolicy} close={expclose}  setClick={setExpClose} collector_name={activeKeyword}/>

      </div>
    </>
  );
};

export default LogManagement;
