import React, { useRef, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import CodeEditor from './CodeEditor';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect } from 'react';
import { fget, fpost } from '../../../axios/axios';

const EditPolicy = () => {
    const { collector_name, pipeline_name } = useParams();
    const [policyName, setPolicyName] = useState("");
    const [activeTab, setActiveTab] = useState("input");
    const inputJsonRef = useRef(null);
    const filterJsonRef = useRef(null);
    const outputJsonRef = useRef(null);
    const [workers, setWorkers] = useState(1);
    const [batchSize, setBatchSize] = useState(1);
    const [inputCheckFlag, setInputCheckFlag] = useState(false);
    const [filterCheckFlag, setFilterCheckFlag] = useState(false);
    const [outputCheckFlag, setOutputCheckFlag] = useState(false);
    const showDetails = () => {
        fget(`${apiBaseUrl}/logstash/configs/config?pipeline_name=${pipeline_name}&collector_name=${collector_name}`).then(({ data }) => {
            setPolicyName(data.pipeline_name)
            inputJsonRef.current = atob(data?.input_conf)
            filterJsonRef.current = atob(data?.filter_conf)
            outputJsonRef.current = atob(data?.output_conf)
            setWorkers(data.workers)
            setBatchSize(data.batch_size)
        }).catch((err) => {
            console.log("Error", err)
        })
    }
    useEffect(() => {
        showDetails()
    }, [])

    const convertToBase64 = (json) => {
        try {
            return btoa(unescape(encodeURIComponent(json)));
        } catch (error) {
            console.error("Failed to convert JSON to Base64", error);
            return null;
        }
    }
    const checkJson = (json) => {
        if (json.error) {
            return false;
        }
        return true;
    }
    const apiBaseUrl = localStorage.getItem("apiBaseUrl")

    const CreatePolicy = () => {
        const inputJsonBase64 = convertToBase64(inputJsonRef.current);
        const filterJsonBase64 = convertToBase64(filterJsonRef.current);
        const outputJsonBase64 = convertToBase64(outputJsonRef.current);
        fpost(`${apiBaseUrl}/logstash/configs/create_pipeline?collector_name=${collector_name}`, {
            pipeline_name: policyName,
            input_conf: inputJsonBase64,
            filter_conf: filterJsonBase64,
            output_conf: outputJsonBase64,
            workers,
            batchSize
        }).then((res) => {
            console.log("Policy Created", res)
        }).catch((err) => {
            console.log("Error",)
        })
    }
    const handleSave = (e) => {
        e.stopPropagation();

            CreatePolicy();
    };
    return (
        <div
            className="col-12 p-4  bg-white rounded-3"
        >
            <div className="d-flex justify-content-between mb-3">
                <h5 className="mb-0">Edit Policy</h5>
                <button className="btn btn-primary" onClick={(e) => handleSave(e)}>
                    Save
                </button>
            </div>
            <form style={{
                maxHeight: "80vh",
                overflowY: "auto"
            }} >
                {/* User Name */}
                {/* <div className="d-flex flex-column gap-2"> */}
                <div className="form-group border rounded-3 p-4 mb-3">
                    <div className="d-flex flex-row gap-2 mb-4">
                        <div className="form-group col-3">
                            <label className="font-weight-bold" htmlFor="userName">
                                Policy Name
                            </label>
                            <input
                                type="text"
                                id="policyName"
                                name="policyName"
                                className="form-control mt-2"
                                value={policyName}
                                onChange={(e) => setPolicyName(e.target.value)}
                                required
                            />
                        </div>

                    </div>
                    <div className="d-flex flex-row gap-2 mb-4 mt-3">
                        <div className="form-group col-3">
                            <label className="font-weight-bold" htmlFor="userName">
                                Workers
                            </label>
                            <input
                                type="text"
                                id="workers"
                                name="workers"
                                className="form-control mt-2"
                                value={workers}
                                onChange={(e) => setWorkers(e.target.value)}
                                required
                            />
                        </div>

                    </div>
                    <div className="d-flex flex-row gap-2 mb-4">
                        <div className="form-group col-3">
                            <label className="font-weight-bold" htmlFor="userName">
                                Batch Size
                            </label>
                            <input
                                type="text"
                                id="policyName"
                                name="batchSize"
                                className="form-control mt-2"
                                value={batchSize}
                                onChange={(e) => setBatchSize(e.target.value)}
                                required
                            />
                        </div>

                    </div>
                    <Tabs
                        defaultActiveKey="input"
                        id="uncontrolled-tab-example"
                        className="mb-4"
                        activeKey={activeTab}
                        onSelect={(e) => setActiveTab(e)}
                    >
                        <Tab
                            eventKey="input"
                            // title="Process"
                            title={
                                <React.Fragment>
                                    Input
                                </React.Fragment>
                            }
                        >
                            <CodeEditor innerRef={inputJsonRef} activeTab={activeTab} collector_name={collector_name} setCheckFlag={setInputCheckFlag} checkFlag={inputCheckFlag} isEdit={true}/>
                        </Tab>

                        <Tab
                            eventKey="filter"
                            // title="Process"
                            title={
                                <React.Fragment>
                                    Filter
                                </React.Fragment>
                            }
                        >
                            <CodeEditor innerRef={filterJsonRef} activeTab={activeTab} collector_name={collector_name} setCheckFlag={setFilterCheckFlag} checkFlag={filterCheckFlag} isEdit={true}/>

                        </Tab>
                        <Tab
                            eventKey="output"
                            // title="Process"
                            title={
                                <React.Fragment>
                                    Output
                                </React.Fragment>
                            }
                        >
                            <CodeEditor innerRef={outputJsonRef} activeTab={activeTab} collector_name={collector_name} setCheckFlag={setOutputCheckFlag} checkFlag={outputCheckFlag} isEdit={true}/>
                        </Tab>

                    </Tabs>
                   
                </div>

            </form>
            <ToastContainer />
        </div>
    );
};

export default EditPolicy;