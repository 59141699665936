import React, { use, useEffect, useState } from "react";
import { fget, fpost, fpostCy } from "../../axios/axios";
import {UnControlled as CodeMirror} from 'react-codemirror2'

import { Tabs, Tab, Table, Spinner } from "react-bootstrap";
import styles from "./PolicySidebar.styles";
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import { filter } from "jszip";
import { Link } from "react-router-dom";
import { Pencil } from "phosphor-react";
import { toast } from "react-toastify";
require('codemirror/mode/xml/xml');
require('codemirror/mode/javascript/javascript');

export default function PolicySidebar(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [nodeData, setNodeData] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [inputJson, setInputJson] = useState();
  const [filterJson, setFilterJson] = useState();
  const [outputJson, setOutputJson] = useState();
  const [testOutput, setTestOutput] = useState();

  const apiBaseUrl = localStorage.getItem("apiBaseUrl")

  const testConf = (e)=>{
    e.stopPropagation();

    fget(`${apiBaseUrl}/logstash/configs/test?collector_name=${props?.collector_name}&pipeline_name=${props?.data?.pipeline_name}`,{
    }).then((res)=>{
        if(res.status===200){
        console.log("Test Conf",res)
        // toast.success("Test Successfull")
        setTestOutput(res.data)
        }
        else{
          toast.error("Test Failed")
        }
    }).catch((err)=>{
        console.log("Error",)
        toast.error("Test Failed")
    })
}
 useEffect(()=>{
    if(props.close){
        setIsOpen(true)
    }
    else{
        setIsOpen(false)
    }
 },[props.close])
  useEffect(() => {
    if(props.data){
      setInputJson(atob(props.data?.input_conf))
      setFilterJson(atob(props.data?.filter_conf))
      setOutputJson(atob(props.data?.output_conf))
    }
  }, [props.data]);
  const handleClose = () => {
    props.setClick(false)
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      {isOpen ? (
        <div
          className="flex w-full h-full shadow flex-col bg-white rounded-3 col-4"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
          data-te-offcanvas-init
          style={styles.sidebarWrap}
        >
          <div className="d-flex flex-row items-center justify-content-between p-4">
            <h5
              className="mb-0 font-semibold leading-normal"
              id="offcanvasRightLabel"
            >
                Policy Details
            </h5>
            <div className="d-flex gap-2">
                <Link to={`/settings/managed-ingestion-edit/${props?.collector_name}/${props?.data?.pipeline_name}`}><Pencil  size={16}/></Link>
                <button type="button" className="btn-close" aria-label="Close" onClick={handleClose}>
                </button>

            </div>
          </div>
          <div className="mb-2 ms-4">
                          <div className="modal-row-item">
                            <span className="modal-label col-3">Policy Name</span>
                            <span className="modal-text">
                              {props?.data?.pipeline_name}
                            </span>
                          </div>
                          <div className="modal-row-item">
                            <span className="modal-label col-3">Workers</span>
                            <span className="modal-text">
                              {props?.data?.workers}
                            </span>
                          </div>
                          <div className="modal-row-item">
                            <span className="modal-label col-3">Batch Size</span>
                            <span className="modal-text">
                              {props?.data?.batch_size}
                            </span>
                          </div>
          </div>

          <div className="offcanvas-body flex-grow overflow-y-auto px-4 pb-2 react-flow-sidebar">
            <Tabs defaultActiveKey="input" aria-label="Tabs with underline">
              <Tab eventKey="input" title="Input" >
            
                <div className="py-3" style={styles.sidebarContentWrap}>

                  {props?.data?.input_conf ? <CodeMirror className="bg-zinc-400 overflow-scroll" style={{ height: 'calc(100vh - 19.5rem)' }} src={props.data} 
                                      options={{
                                        mode: 'xml',
                                        theme: 'material',
                                        lineNumbers: true,
                                        contenteditable: false
                                }}
                                value={inputJson}
                  />
                    : <div>
                      No Data Found about the process
                    </div>}
                  {/* <pre>{JSON.stringify(nodeData,null,2)}</pre> */}
                </div>
              </Tab>
              <Tab eventKey="filter" title="Filter" >
                <div className="py-3" style={styles.sidebarContentWrap}>

                  {props?.data?.filter_conf ? <CodeMirror className="bg-zinc-400 overflow-scroll" style={{ height: 'calc(100vh - 19.5rem)' }} src={props.data} 
                                options={{
                                  mode: 'xml',
                                  theme: 'material',
                                  lineNumbers: true,
                                  contenteditable: false
                          }}
                          value={filterJson}
                  />
                    : <div>
                      No Data Found about the process
                    </div>}
                  {/* <pre>{JSON.stringify(nodeData,null,2)}</pre> */}
                </div>
              </Tab>
              <Tab eventKey="output" title="Output" >
                <div className="py-3" style={styles.sidebarContentWrap}>

                  {props?.data?.output_conf ? <CodeMirror className="bg-zinc-400 overflow-scroll" style={{ height: 'calc(100vh - 19.5rem)' }} 
                                options={{
                                  mode: 'xml',
                                  theme: 'material',
                                  lineNumbers: true,
                                  contenteditable: false
                          }}
                          value={outputJson}
                  />
                    : <div>
                      No Data Found about the process
                    </div>}
                  {/* <pre>{JSON.stringify(nodeData,null,2)}</pre> */}
                </div>
              </Tab>
              <Tab eventKey="test" title="Test" >
                <div className="py-3" style={styles.sidebarContentWrap}>
                <div className="mt-auto d-flex justify-content-end p-2">
                  <button type="button" className="btn btn-primary" onClick={(e) => testConf(e)}>Test</button>
                </div>               
                     {testOutput&&<div style={{ padding: '1rem', backgroundColor: '#f5f5f5', borderRadius: '5px', overflow: 'auto' }}>
                    <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                      {testOutput}
                    </pre>
                  </div>}
                  {/* <pre>{JSON.stringify(nodeData,null,2)}</pre> */}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}